<template>
  <div class="container-fluid mt--6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 col-md-8 text-center">
            <div class="text-lg text-red pb-2">
              Invite every 3 friends to get free 50 GBP/EUR
            </div>
            <div class="text-sm pb-2">
              This is your referral link and QRcode. Share to for invite and
              make money!
            </div>
            <el-input
              ref="reflink"
              class="form-control-alternative input-btn-group-primary"
              v-model="reflink"
              readonly
            >
              <template #append>
                <el-button class="btn" icon="el-icon-document-copy" @click="copyRefLink()">Copy</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-9 col-md-12">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0">Introducing Program <el-button type="primary" size="small" class="btn" @click="adsIntroducer=true">Read More</el-button></h3>
              </div>
              <div class="col text-right" v-if="!isMobile">
                <el-button type="success" class="btn" icon="el-icon-download" @click="excelExport()" :title="'Export excel '+tabType">Excel</el-button>
                <el-button type="danger" class="btn" icon="el-icon-download" @click="pdfExport()" :title="'Export pdf '+tabType">PDF</el-button
                >
              </div>
            </div>
          </div>

          <div class="card-body" v-loading="loading">
            <el-tabs type="card" v-model="tabType">
              <el-tab-pane label="Introduced" name="downline">
                <!-- Introduced -->
                <el-table  v-if="!isMobile" :data="tableDownline" border style="width: 100%" size="mini">
                  <el-table-column prop="approve_date" label="Date" width="180"></el-table-column>
                  <el-table-column prop="name" label="Name"></el-table-column>
                  <el-table-column prop="membercode" label="Member Code"></el-table-column>
                  <el-table-column prop="status" label="Status"></el-table-column>
                </el-table>
                 <div class="list-group" v-if="isMobile">
                  <div 
                    class="list-group-item"
                    v-for="(item, index) of tableDownline" :key="index"
                    >
                    
                    <div class="downline-member">{{ item.membercode}}</div>
                    <div class="downline-name">{{item.name}}</div>
                    <div class="downline-date">{{ item.approve_date }}</div>
                    <span :class="`badge ${item.status == 'Active' ? 'badge-success' : 'badge-light'} badge-pill justify-content-end`">{{item.status}}</span>                    
                  </div>
        
                </div>
              </el-tab-pane>
              <el-tab-pane label="Income" name="income">
                <!-- income -->
                <div class="row justify-content-md-center">
                  <div class="col-sm-12 col-md-3 text-center mb-2">
                    <el-select
                      v-model="incomeQuery.bankholderid"
                      placeholder="BankholderID"
                      class="form-control-alternative"
                      @change="selectCurrency"
                    >
                      <el-option v-for="item of bankAccount" :key="item.uid" :label="`${item.currency} (${item.cardholderid})`" :value="item.cardholderid"></el-option>
                    </el-select>
                  </div>
                  <div class="col-sm-12 col-md-3 text-center mb-2">
                    <el-date-picker
                      v-model="incomeQuery.income_date_from"
                      type="date"
                      placeholder="Start date"
                      class="form-control-alternative"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-sm-12 col-md-3 text-center mb-2">
                    <el-date-picker
                      v-model="incomeQuery.income_date_to"
                      type="date"
                      placeholder="End date"
                      class="form-control-alternative"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-auto col-sm-12">
                    <el-button class="btn btn-primary" @click="getIncome()" v-loading="quering">Query</el-button>
                  </div>
                </div>
                <div class="my-2 text-center">
                  <el-tag>Incomes in the scope: {{ this.incomeTotal + " " + this.incomeCurrency}}</el-tag>
                </div>
                <el-table  v-if="!isMobile" :data="tableIncome" border style="width: 100%" size="mini">
                  <el-table-column prop="date" label="Date" width="180"></el-table-column>
                  <el-table-column prop="membercode" label="Member Code"></el-table-column>
                  <el-table-column prop="amount" label="Amount"></el-table-column>
                  <el-table-column prop="currency" label="Currency"></el-table-column>

                </el-table>
                <div class="list-group" v-if="isMobile">
                  <div 
                    class="list-group-item"
                    v-for="(item, index) of tableIncome" :key="index"
                    >
                    
                    <div class="downline-member">{{ item.membercode}}</div>
                    <div class="downline-name">{{item.amount}}</div>
                    <div class="downline-date">{{ item.date }}</div>
                  </div>
        
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <qr-code :text="reflink" :size="150" class="qrcode"></qr-code>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      title="ADS"
      :visible.sync="adsIntroducer"
      direction="ttb"
      size="100%">
      <img class="img-autosize img-center" src="img/brand/introducingprogram.png" alt="">
      <div class="text-center"><el-button type="danger" @click="adsIntroducer=false">Close</el-button></div>
    </el-drawer>
  </div>
</template>

<script>
import Utils from '@/utils'
import IntroducerApi from '@/api/introducer'
import { isMobile } from '@/utils/helpers'
export default {
  computed:{
    isMobile() {
      return isMobile()
    },
  },
  data() {
    return {
      tabType: 'downline',
      quering: false,
      loading: false,
      adsIntroducer: false,
      reflink:"",
      tableDownline: [],
      tableIncome: [],
      downlineToken: "",
      incomeQuery: {
        bankholderid: "",
        currency: "",
        income_date_from: new Date(),
        income_date_to: new Date(),
      },
      incomeToken: "",
      incomeTotal: 0,
      incomeCurrency: "",
      bankAccount:[]
    };
  },
  methods: {
    async getBankAccount(){
      this.bankAccount = []
      this.loading = true
      this.bankAccount = await Utils.getBankAccount();
      this.loading = false
    },
    getDownline(){
      this.loading = true
      IntroducerApi.getDownline().then(({result, data})=>{
        this.loading = false
        if(result){
          this.tableDownline = data.downline
          this.downlineToken = data.export_token
        }
      })
    },
    getIncome(){
      this.incomeQuery.income_date_from = Utils.dateFormat(this.incomeQuery.income_date_from )
      this.incomeQuery.income_date_to = Utils.dateFormat(this.incomeQuery.income_date_to )
      this.quering = true
      IntroducerApi.getIncome(this.incomeQuery).then(({result, data})=>{
        this.quering = false
        if(result){
          this.tableIncome = data.income_list
          this.incomeToken = data.export_token
          this.incomeTotal = data.total_scope_income
          this.incomeCurrency = data.currency
        }
      })
    },
    selectCurrency(value){
      const result = Utils.findObject(this.bankAccount, value, 'cardholderid')
      this.incomeQuery.currency = result.currency
    },
    getReflink(){
      IntroducerApi.getReflink().then(({result, data})=>{
        if(result){
          this.reflink = data
        }
      })
    },
    excelExport(){
      if(this.tabType === 'downline'){
        IntroducerApi.exportDownline('excel', this.downlineToken)
      }else{
        IntroducerApi.exportIncome('excel', this.incomeToken)
      }
    },
    pdfExport(){
      if(this.tabType === 'downline'){
        IntroducerApi.exportDownline('pdf', this.downlineToken)
      }else{
        IntroducerApi.exportIncome('pdf', this.incomeToken)
      }
    },
    copyRefLink(){
      this.$refs.reflink.select();
      document.execCommand("copy");
      this.$message({
          message: 'Congrats, This is your referral link Copied.',
          type: 'success'
        });
    }
  },

  async created(){
    this.getReflink()
    this.getDownline()
    await this.getBankAccount()
  }
};
</script>

<style lang="scss">
.downline-member {
  color: #1a4a66;
  font-size: 12px;
  white-space: normal;
  font-weight: 500;
}

.downline-name {
  color: #41a8e4;
  font-size: 14px;
}
 
.downline-date {
  color: #868685;
  font-size: 10px;
}
</style>