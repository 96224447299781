import request from './request'
import {uuid} from 'vue-uuid'
import Auth from './auth'
const IntroducingApi = {
  getDownline(){
    return request({
      url: `/v1/introducing/downline`,
      method: "get"
    });
  },
  exportDownline(type, export_token, filename){
    const token = Auth.getToken()
    const baseURL = (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION
    const link = document.createElement('a')
    link.href = `${baseURL}/v1/introducing/downline/export/${type}?token=${token}&export_token=${export_token}`;
    if(filename){
      link.download = filename
    }
    link.click()
  },
  getIncome(data){
    data.idempotency = uuid.v1()
    return request({
      url: `/v1/introducing/income`,
      method: "post",
      data: data
    });
  },
  exportIncome(type, export_token, filename){
    const token = Auth.getToken()
    const baseURL = (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION
    const link = document.createElement('a')
    link.href = `${baseURL}/v1/introducing/income/export/${type}?token=${token}&export_token=${export_token}`;
    if(filename){
      link.download = filename
    }
    link.click()
  },

  getReflink(){
    return request({
      url: `/v1/introducing/reflink`,
      method: "get"
    });
  }
}

export default IntroducingApi